export const initializeMSW = async () => {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    const { server } = await import('./server');

    server.listen({
      onUnhandledRequest: 'bypass',
    });

    return;
  }

  const { worker } = await import('./browser');

  worker.start({
    onUnhandledRequest: 'bypass',
  });
};
