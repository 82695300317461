import { action, autorun, computed, makeAutoObservable, makeObservable, observable } from 'mobx';

import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

class MenuStore implements BaseStore<MenuStore> {
  menu_open = null;
  sub_menu_open = null;
  rencar_notice_id = null;
  rencar_notice_title = null;
  company_notice_id = null;
  company_notice_title = null;
  transfer_car_notice_id = null;
  transfer_car_notice_title = null;
  notification_badge_count = 0;
  currentMenuPath = null;
  selfContractPendingCount = 0;
  isCarEditPageMove = true;
  constructor() {
    makeAutoObservable(this);
  }

  setValue = (data) => {
    this[data.key] = data.value === '' ? null : data.value;
  };

  setBadge = (cnt, cb) => {
    this.notification_badge_count = cnt ? cnt : 0;

    if (cb) {
      cb();
    }
  };

  setSelfContractPendingCount = (value) => {
    this.selfContractPendingCount = value;
  };

  setIsCarEditPageMove = (value) => {
    this.isCarEditPageMove = value;
  };

  setObject = (data) => _setObject(this, data);
}

export default MenuStore;
