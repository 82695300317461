import { CarOption, getCarGroupOptionsApi } from 'api/rentCompanyCarGroup/getCarGroupOptionsApi';
import { makeAutoObservable } from 'mobx';

import { _setObject, BaseStore } from './BaseStore.type';
import LoadingStore from './LoadingStore';

/**
 * 기존 차량 등록에서 사용하고 있는 마지막 옵션 인덱스(어라운드뷰).
 * api 리스트에서 0 ~ 8 까지의 옵션들이 기존 부터 차량 등록에서 사용중인 옵션들.
 */
const LAST_ORIGIN_OPTION_INDEX = 8;

/**
 * 차량 등록에서 사용하는 기타 옵션의 라벨 값.
 */
export const ETC_LABEL = '기타(직접입력)';

/**
 * 차량 등록에서 사용하는 기타 옵션의 상태명.
 */
export const ETC_STATE_NAME = 'other_option';

/**
 * 그룹 차량에서 옵션 체크박스 노출시 보여줘야할 순서.
 * 옵션이 추가되거나 순서가 변경되면 해당 배열의 순서를 변경해서 재정렬 해줘야한다.
 */
const SORTED_CAR_GROUP_OPTION_NAMES = [
  'navigation',
  'rear_sensor',
  'rear_camera',
  'driver_airbag',
  'passenger_airbag',
  'cooled_seat',
  'heated_seat',
  'heated_handle',
  'around_view',
  'forward_collision_avoidance_assist',
  'lane_departure_warning_system',
  'sunroof',
  'smart_key',
  'dash_cam',
  'four_wheel_drive',
  'bluetooth',
  'apple_carplay',
  'android_auto',
  'auto_sliding_door',
  'non_smoking',
  'pet_friendly',
  'fishing_gear',
] as const;

/**
 * 차량 등록 페이지의 옵션과 그룹 페이지의 옵션이 달라진 히스토리 정리.
 * 기존 대차로 차량을 빌렸을 때는 차량 옵션이 크게 중요하지 않았음.
 * 그래서 차량 등록 페이지에는 기타 옵션을 포함한 10가지의 옵션들을 사용중이였음.
 * 그런데 추후 차량이 리스트에 노출되고 그룹이 등록 되면서 여러 차량 옵션들이 필요하게됨.
 * 그룹에 등록되지 않는 기존 차량들은 그래도 옵션이 중요하지 않기 때문에 그룹과 옵션이 다르게 노출되도록 정해짐.
 * 추가적으로 차량 DB 테이블에 각 옵션들이 칼럼들로 저장이 되어 있었는데 추가되는 많은 옵션들을 칼럼으로 일일히 추가해서 적용할 수 없음.
 * 그래서 기존 차량 옵션들과 그룹에서 사용될 추가된 옵션들이 따로 테이블로 만들어서 구성됨. (api로 받아오는 리스트)
 */
class CarOptionStore implements BaseStore<CarOptionStore> {
  /** 기존 차량등록에서 사용중이였던 차량 옵션. */
  private carOptions: CarOption[] | null = null;
  /**
   * api로 받아오는 모든 차량 옵션.
   * 그룹에서 사용하는 옵션들.
   * 차량 등록 페이지에서도 그룹에서 등록한 옵션들은 노출됨.
   */
  private carGroupOptions: CarOption[] | null = null;
  /** 캠핑카 차량 옵션. */
  private campingCarOptions: CarOption[] | null = null;
  private loadingStore: LoadingStore;
  /** api 호출 중에 initialize 요청이 다시 들어오면 호출을 막기위한 용도 */
  private isInitializing = false;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
    makeAutoObservable(this);
  }

  setObject = (data) => _setObject(this, data);

  get isInitialized() {
    return (
      this.carGroupOptions !== null && this.carOptions !== null && !this.campingCarOptions !== null
    );
  }

  async initialize() {
    if (this.isInitializing) {
      return;
    }

    this.isInitializing = true;
    this.loadingStore.on();
    const { carOptions, campingCarOptions } = await getCarGroupOptionsApi();

    this.carGroupOptions = carOptions;
    this.carOptions = carOptions.slice(0, LAST_ORIGIN_OPTION_INDEX + 1);
    this.campingCarOptions = campingCarOptions;

    this.carGroupOptions.sort(
      (a, b) =>
        SORTED_CAR_GROUP_OPTION_NAMES.findIndex((name) => name === a.name) -
        SORTED_CAR_GROUP_OPTION_NAMES.findIndex((name) => name === b.name)
    );

    this.loadingStore.off();
    this.isInitializing = true;
  }

  getCarGroupOptions() {
    if (!this.isInitialized) {
      return [];
    }

    return this.carGroupOptions;
  }

  getCarOptions() {
    if (!this.isInitialized) {
      return [];
    }

    return this.carOptions;
  }

  getCampingCarOptions() {
    if (!this.isInitialized) {
      return [];
    }

    return this.campingCarOptions;
  }

  /**
   * 차량 등록에서 사용할 차량 옵션 체크박스 상태
   * 기타는 이미 저장된 문자열이 있으면 해당 문자열로 저장.
   */
  getCarOptionStateByIdsAndEtc(optionIds: number[], etcOption: boolean | string = false) {
    const optionState = {};

    if (!this.isInitialized) {
      return optionState;
    }

    this.carOptions.forEach(({ id, name }) => (optionState[name] = optionIds.includes(id)));
    optionState[ETC_STATE_NAME] = etcOption;

    return optionState;
  }

  /**
   * 차량 그룹 - 차량 옵션 체크박스 상태.
   */
  getGroupCarOptionStateByIds(optionIds: number[]) {
    const optionState = {};

    if (!this.isInitialized) {
      return optionState;
    }

    this.carGroupOptions.forEach(({ id, name }) => (optionState[name] = optionIds.includes(id)));
    this.campingCarOptions.forEach(({ id, name }) => (optionState[name] = optionIds.includes(id)));

    return optionState;
  }

  getCarGroupOptionNamesByIds(optionIds: number[]) {
    if (!this.isInitialized) {
      return [];
    }

    return this.carGroupOptions
      .filter(({ id }) => optionIds.includes(id))
      .map(({ name_kor }) => name_kor);
  }

  getCampingCarOptionNamesByIds(optionIds: number[]) {
    if (!this.isInitialized) {
      return [];
    }

    return this.campingCarOptions
      .filter(({ id }) => optionIds.includes(id))
      .map(({ name_kor }) => name_kor);
  }

  getCarOptionNamesByState(optionState: { [stateName: string]: boolean | string }) {
    if (!this.isInitialized) {
      return [];
    }

    const etcOption = optionState[ETC_STATE_NAME];
    const names = this.carOptions
      .filter(({ name }) => name !== ETC_STATE_NAME && optionState[name])
      .map(({ name_kor }) => name_kor);

    // 기타 옵션이 있으면 마지막에 위치
    return typeof etcOption === 'string' ? [...names, etcOption] : names;
  }

  getCarGroupOptionNamesByState(optionState: { [stateName: string]: boolean | string }) {
    if (!this.isInitialized) {
      return [];
    }

    return this.carGroupOptions
      .filter(({ name }) => name !== ETC_STATE_NAME && optionState[name]) // 그룹 옵션에는 기타 옵션이 없음.
      .map(({ name_kor }) => name_kor);
  }

  getCampingCarOptionNamesByState(optionState: { [stateName: string]: boolean | string }) {
    if (!this.isInitialized) {
      return [];
    }

    return this.campingCarOptions
      .filter(({ name }) => name !== ETC_STATE_NAME && optionState[name]) // 캠핑카 옵션에는 기타 옵션이 없음.
      .map(({ name_kor }) => name_kor);
  }

  getCarOptionIdsByState(optionState: { [stateName: string]: boolean }) {
    return Object.entries(optionState)
      .filter(([optionName, isActivated]) => isActivated && optionName !== ETC_STATE_NAME) // 기타 옵션은 id가 없다.
      .map(([optionName]) => this.carOptions.find(({ name }) => name === optionName)?.id)
      .filter((id) => id);
  }

  getAllOptionIdsByState(optionState: { [stateName: string]: boolean }) {
    return Object.entries(optionState)
      .filter(([optionName, isActivated]) => isActivated && optionName !== ETC_STATE_NAME) // 기타 옵션은 id가 없다.
      .map(
        ([optionName]) =>
          [...this.carGroupOptions, ...this.campingCarOptions].find(
            ({ name }) => name === optionName
          ).id
      );
  }
}
export default CarOptionStore;
