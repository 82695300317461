/**
 *
 * @param { <Array> } data
 */

export const majorInsuranceData = [
  { value: '삼성', label: '삼성', match: 'samsung' },
  { value: '현대', label: '현대', match: 'hyundae' },
  { value: 'DB', label: 'DB', match: 'db' },
  { value: 'KB', label: 'KB', match: 'kb' },
  { value: '메리츠', label: '메리츠', match: 'meriz' },
  { value: '한화', label: '한화', match: 'hanwha' },
  { value: 'AXA', label: 'AXA', match: 'axa' },
  { value: '흥국', label: '흥국', match: 'heungguk' },
  { value: '롯데', label: '롯데', match: 'lotte' },
  { value: 'MG', label: 'MG', match: 'mg' },
  { value: '캐롯', label: '캐롯', match: 'carot' },
  { value: '하나', label: '하나(구 더케이)', match: 'hana' },
  { value: '기타', label: '기타', match: 'etc', isViewSelect: true },
];

export const etcInsuranceData = [
  { label: '화물공제', value: '화물공제', match: 'etc' },
  { label: '택시공제', value: '택시공제', match: 'etc' },
  { label: '버스공제', value: '버스공제', match: 'etc' },
  { label: '개인택시공제', value: '개인택시공제', match: 'etc' },
  { label: '전세버스공제', value: '전세버스공제', match: 'etc' },
  { label: '렌트카공제', value: '렌트카공제', match: 'etc' },
  { label: '기타손해사정', value: '기타손해사정', match: 'etc' },
  { label: '마스타자동차관리', value: '마스타자동차관리', match: 'etc' },
  { label: '신한마스타오토서비스', value: '신한마스타오토서비스', match: 'etc' },
];

export const basicCompensationOptions = [
  { label: '1천5백만원', value: 1500 },
  { label: '2천만원', value: 2000 },
  { label: '2천5백만원', value: 2500 },
  { label: '3천만원', value: 3000 },
  { label: '3천5백만원', value: 3500 },
  { label: '4천만원', value: 4000 },
  { label: '4천5백만원', value: 4500 },
  { label: '5천만원', value: 5000 },
  { label: '7천만원', value: 7000 },
  { label: '1억원', value: 10000 },
  { label: '2억원', value: 20000 },
  { label: '5억원', value: 50000 },
  { label: '10억원', value: 100000 },
];

export const objectCompensationOptions = [
  { label: '2천만원', value: 2000 },
  { label: '2천5백만원', value: 2500 },
  { label: '3천만원', value: 3000 },
  { label: '3천5백만원', value: 3500 },
  { label: '4천만원', value: 4000 },
  { label: '4천5백만원', value: 4500 },
  { label: '5천만원', value: 5000 },
  { label: '7천만원', value: 7000 },
  { label: '1억원', value: 10000 },
  { label: '2억원', value: 20000 },
  { label: '5억원', value: 50000 },
  { label: '10억원', value: 100000 },
];

export const selfCriticalCompensationOptions = [
  { label: '1천5백만원', value: 1500 },
  { label: '3천만원', value: 3000 },
  { label: '5천만원', value: 5000 },
  { label: '1억원', value: 10000 },
];

export const selfCompensationOptions = [
  { label: '1천5백만원', value: 1500 },
  { label: '3천만원', value: 3000 },
  { label: '5천만원', value: 5000 },
];
